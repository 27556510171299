import React, { useEffect, useRef, useState } from "react";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import CircularProgress from "@mui/material/CircularProgress";
import ZoomInRoundedIcon from "@mui/icons-material/ZoomInRounded";
import ZoomOutRoundedIcon from "@mui/icons-material/ZoomOutRounded";

import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { API_HOST, NAVBAR_BLUE } from "../utils/constants";
import ImageUpload from "../components/imageUpload/ImageUpload";

const borderStyle = {
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
};

const initialState = {
  documentType: "Cheque",
  payee: "",
  date: "",
  amount: "",
  accountHolder: "",
  ifsc: "",
  account: "",
  bankName: "",
  bankBranch: "",
  bankAddress: "",
  bankCity: "",
  bankState: "",
  bankContact: "",
};

export default function Result() {
  const [isProcessing, setIsprocessing] = useState(false);

  const [files, setFiles] = useState([]);
  const [imageURL, setImageURL] = useState();
  const [fields, setFields] = useState(initialState);

  const accountNumberHighlightRef = useRef(null);
  const amountHighlightRef = useRef(null);
  const payeeNameHighlightRef = useRef(null);
  const accountHolderNameHighlightRef = useRef(null);
  const dateHighlightRef = useRef(null);

  useEffect(() => {
    document.addEventListener("dragenter", handleDrop, false);

    return () => {
      document.removeEventListener("dragenter", handleDrop, false);
    };
  });

  const handleDrop = (event) => {
    setImageURL(null);
    setFiles([]);
    setFields(initialState);
  };

  const handleChange = (event) => {
    const fieldName = event.target.name;
    let fieldValue = event.target.value;
    setFields(() => ({ ...fields, [fieldName]: fieldValue }));
  };

  return (
    <section className="result container-fluid">
      {isProcessing ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ width: "80vw", height: "50vh" }}
        >
          <CircularProgress size="60px" style={{ color: NAVBAR_BLUE }} />
        </div>
      ) : (
        <>
          <div className="row p-4" style={borderStyle}>
            <div className="col-6 thumbnail__container">
              {imageURL ? (
                <TransformWrapper
                  defaultScale={1}
                  defaultPositionX={200}
                  defaultPositionY={100}
                  wheel={{ disabled: 1 }}
                  doubleClick={{ disabled: 1 }}
                >
                  {({ zoomIn, zoomOut, resetTransform }) => (
                    <>
                      <div className="thumbnail-buttons m-2">
                        <Button
                          size="large"
                          className="text-light"
                          variant="contained"
                          color="primary"
                          style={{ marginRight: "1rem" }}
                          onClick={() => zoomIn()}
                        >
                          <ZoomInRoundedIcon />
                        </Button>
                        <Button
                          size="large"
                          className="text-light"
                          variant="contained"
                          color="primary"
                          style={{ marginRight: "1rem" }}
                          onClick={() => zoomOut()}
                        >
                          <ZoomOutRoundedIcon />
                        </Button>

                        <Button
                          size="large"
                          className="text-light mr-2"
                          style={{
                            fontSize: "13px",
                          }}
                          variant="contained"
                          color="primary"
                          onClick={() => resetTransform()}
                        >
                          Reset
                        </Button>
                      </div>
                      <TransformComponent
                        style={{ width: "100%", marginTop: "15%" }}
                      >
                        {/* payee */}
                        <div
                          ref={payeeNameHighlightRef}
                          style={{
                            position: "absolute",
                            top: "17%",
                            left: "6%",
                            backgroundColor: "#54f542",
                            height: "15%",
                            width: "80%",
                            opacity: "0",
                            zIndex: "33",
                          }}
                        ></div>

                        {/* date */}
                        <div
                          ref={dateHighlightRef}
                          style={{
                            position: "absolute",
                            top: "2%",
                            right: "2%",
                            backgroundColor: "#54f542",
                            height: "20%",
                            width: "25%",
                            opacity: "0",
                            zIndex: "33",
                          }}
                        ></div>

                        {/* account number */}
                        <div
                          ref={accountNumberHighlightRef}
                          style={{
                            position: "absolute",
                            top: "43%",
                            left: "10%",
                            backgroundColor: "#54f542",
                            height: "20%",
                            width: "40%",
                            opacity: "0",
                            zIndex: "33",
                          }}
                        ></div>

                        {/* account holder name */}
                        <div
                          ref={accountHolderNameHighlightRef}
                          style={{
                            position: "absolute",
                            bottom: "15%",
                            right: "3%",
                            backgroundColor: "#54f542",
                            height: "25%",
                            width: "30%",
                            opacity: "0",
                            zIndex: "33",
                          }}
                        ></div>

                        {/* amount */}
                        <div
                          ref={amountHighlightRef}
                          style={{
                            position: "absolute",
                            top: "32%",
                            right: "3%",
                            backgroundColor: "#54f542",
                            height: "25%",
                            width: "30%",
                            opacity: "0",
                            zIndex: "33",
                          }}
                        ></div>
                        <img
                          src={`${API_HOST}${imageURL}`}
                          alt="Scanned document image"
                        />
                      </TransformComponent>
                    </>
                  )}
                </TransformWrapper>
              ) : (
                <ImageUpload
                  files={files}
                  setFiles={setFiles}
                  setFields={setFields}
                  setImageURL={setImageURL}
                  setIsprocessing={setIsprocessing}
                />
              )}
            </div>
            <div className="col-6 d-flex align-items-start justify-content-center">
              <div className="w-100 container ">
                {/* <Box
                   component="form"
                   sx={{
                     "& > :not(style)": { m: 1 },
                   }}
                   noValidate
                   autoComplete="off"
                 > */}
                <div className="row d-flex align-items-center">
                  <span className="text-muted col-3">Document Type: </span>
                  <div className=" col-9">
                    <Input
                      className="fw-bold fs-6 w-100"
                      name="documentType"
                      onChange={handleChange}
                      value={fields.documentType}
                      placeholder="Document Type"
                    />
                  </div>
                </div>

                <div className="row mt-4 d-flex align-items-center">
                  <span className="text-muted col-3">Payee Name: </span>
                  <div className=" col-9">
                    <Input
                      className="fw-bold fs-6 w-100"
                      name="payee"
                      onChange={handleChange}
                      value={fields.payee}
                      onFocus={() => {
                        payeeNameHighlightRef.current.style.opacity = "0.4";
                      }}
                      onBlur={() => {
                        payeeNameHighlightRef.current.style.opacity = "0";
                      }}
                      placeholder="Payee Name"
                    />
                  </div>
                </div>

                <div className="row mt-4 d-flex align-items-center">
                  <span className="text-muted col-3">Date: </span>
                  <div className=" col-9">
                    <Input
                      className="fw-bold fs-6 w-100"
                      name="date"
                      onChange={handleChange}
                      type="date"
                      value={fields.date}
                      onFocus={() => {
                        dateHighlightRef.current.style.opacity = "0.4";
                      }}
                      onBlur={() => {
                        dateHighlightRef.current.style.opacity = "0";
                      }}
                      placeholder="Date"
                    />
                  </div>
                </div>

                <div className="row mt-4 d-flex align-items-center">
                  <span className="text-muted col-3"> Amount: </span>
                  <div className=" col-9">
                    <Input
                      className="fw-bold fs-6 w-100"
                      name="amount"
                      onChange={handleChange}
                      value={fields.amount}
                      placeholder="Amount"
                      onFocus={() => {
                        amountHighlightRef.current.style.opacity = "0.4";
                      }}
                      onBlur={() => {
                        amountHighlightRef.current.style.opacity = "0";
                      }}
                    />
                  </div>
                </div>

                <div className="row mt-4 d-flex align-items-center">
                  <span className="text-muted col-3">
                    Account Holder Name:{" "}
                  </span>
                  <div className=" col-9">
                    <Input
                      className="fw-bold fs-6 w-100"
                      name="accountHolder"
                      onChange={handleChange}
                      value={fields.accountHolder}
                      placeholder="Account Holder Name"
                      onFocus={() => {
                        accountHolderNameHighlightRef.current.style.opacity =
                          "0.4";
                      }}
                      onBlur={() => {
                        accountHolderNameHighlightRef.current.style.opacity =
                          "0";
                      }}
                    />
                  </div>
                </div>

                <div className="row mt-4 d-flex align-items-center">
                  <span className="text-muted col-3">Account Number: </span>
                  <div className=" col-9">
                    <Input
                      className="fw-bold fs-6 w-100"
                      name="account"
                      onChange={handleChange}
                      value={fields.account}
                      placeholder="Account Number"
                      onFocus={() => {
                        accountNumberHighlightRef.current.style.opacity = "0.4";
                      }}
                      onBlur={() => {
                        accountNumberHighlightRef.current.style.opacity = "0";
                      }}
                    />
                  </div>
                </div>
                <div className="row mt-4 d-flex align-items-center">
                  <span className="text-muted col-3">Bank IFSC Code: </span>
                  <div className=" col-9">
                    <Input
                      className="fw-bold fs-6 w-100"
                      name="ifsc"
                      onChange={handleChange}
                      value={fields.ifsc}
                      placeholder="Bank IFSC Code"
                    />
                  </div>
                </div>

                <div className="row mt-4 d-flex align-items-center">
                  <span className="text-muted col-3">Bank Name: </span>
                  <div className=" col-9">
                    <Input
                      className="fw-bold fs-6 w-100"
                      name="bankName"
                      onChange={handleChange}
                      value={fields.bankName}
                      placeholder="Bank Name"
                    />
                  </div>
                </div>

                <div className="row mt-4 d-flex align-items-center">
                  <span className="text-muted col-3">Bank Branch: </span>
                  <div className=" col-9">
                    <Input
                      className="fw-bold fs-6 w-100"
                      name="bankBranch"
                      onChange={handleChange}
                      value={fields.bankBranch}
                      placeholder="Bank Branch"
                    />
                  </div>
                </div>

                <div className="row mt-4 d-flex align-items-center">
                  <span className="text-muted col-3">Bank Address: </span>
                  <div className=" col-9">
                    <TextField
                      className="fw-bold fs-6 w-100"
                      name="bankAddress"
                      variant="standard"
                      placeholder="Bank Address"
                      multiline
                      maxRows={4}
                      value={fields.bankAddress}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="row mt-4 d-flex align-items-center">
                  <span className="text-muted col-3">Bank City: </span>
                  <div className=" col-9">
                    <Input
                      className="fw-bold fs-6 w-100"
                      name="bankCity"
                      onChange={handleChange}
                      value={fields.bankCity}
                      placeholder="Bank City"
                    />
                  </div>
                </div>

                <div className="row mt-4 d-flex align-items-center">
                  <span className="text-muted col-3">Bank State: </span>
                  <div className=" col-9">
                    <Input
                      className="fw-bold fs-6 w-100"
                      name="bankState"
                      onChange={handleChange}
                      value={fields.bankState}
                      placeholder="Bank State"
                    />
                  </div>
                </div>

                <div className="row mt-4 d-flex align-items-center">
                  <span className="text-muted col-3">Bank Phone No: </span>
                  <div className=" col-9">
                    <Input
                      className="fw-bold fs-6 w-100"
                      name="bankContact"
                      onChange={handleChange}
                      value={fields.bankContact}
                      placeholder="Bank Phone No."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="d-flex justify-content-center mt-5">
            <Button
              size="large"
              className="text-light"
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          </div> */}
        </>
      )}
    </section>
  );
}
