import React from "react";
import { Link, useLocation } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import logo from "url:../../assets/images/logo.png";

import {
  DEVELOPER_TAB,
  HOME_TAB,
  PERCEPTIVITI,
  WHITE,
} from "../../utils/constants";

export default function Navbar() {
  const { pathname } = useLocation();

  let tabName;
  if (pathname === "/") {
    tabName = HOME_TAB;
    document.title = "Home - Cheque OCR App";
  } else if (pathname === "/developer") {
    tabName = DEVELOPER_TAB;
    document.title = "Developer - Cheque OCR App";
  }

  const handleLinkClick = () => {
    window.location = PERCEPTIVITI;
  };
  return (
    <>
      <AppBar
        position="sticky"
        className="navbar__wrapper d-flex justify-content-center p-2 px-4"
        style={{ backgroundColor: WHITE, color: "#000000", boxShadow: "none" }}
      >
        <Toolbar className="d-flex flex-row justify-content-between">
          <div className="d-flex link">
            <a href="/">
              <img src={logo} height="30" width="183" alt="company logo" />
            </a>
          </div>
          <span className="link h5 mt-3">Cheque OCR App</span>
          <div className="navbar">
            <div>
              <Link
                className={
                  tabName === HOME_TAB
                    ? "nav-link text-uppercase active"
                    : "nav-link text-uppercase"
                }
                to="/home"
              >
                Home
              </Link>
            </div>
            <div>
              <Link
                className={
                  tabName === DEVELOPER_TAB
                    ? "nav-link text-uppercase active"
                    : "nav-link text-uppercase"
                }
                to="/developer"
              >
                Developer
              </Link>
            </div>
            <div
              tabIndex="0"
              role="button"
              className="nav-link text-uppercase"
              variant="text"
              onClick={handleLinkClick}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleLinkClick();
                }
              }}
            >
              About
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}
