/* change date from dd-mm-yyyy to yyyy-MM-dd format */
const changeDateFormat = (receivedDate) => {
  if (
    receivedDate === null ||
    receivedDate === undefined ||
    receivedDate === ""
  ) {
    return "";
  }

  let obtainedDate = receivedDate.split("-");
  obtainedDate = `${obtainedDate[2]}-${obtainedDate[1]}-${obtainedDate[0]}`;

  const date = new Date(obtainedDate);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }
  let dateInDesiredFormat = date.getDate();
  if (dateInDesiredFormat < 10) {
    dateInDesiredFormat = `0${dateInDesiredFormat}`;
  }
  const dateInRequiredFormat = `${year}-${month}-${dateInDesiredFormat}`;
  return dateInRequiredFormat;
};

export default changeDateFormat;
