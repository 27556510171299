import { API_HOST } from "./constants";

let csrfToken = null;

async function getCsrfToken() {
  const response = await fetch(`${API_HOST}/csrf/`, {
    credentials: "include",
  });
  const data = await response.json();
  csrfToken = data.csrfToken;
  return csrfToken;
}

export default async function callAPI(method, route, body) {
  let response;
  if (method === "POST") {
    response = await fetch(`${API_HOST}/${route}`, {
      method,
      headers: { "X-CSRFToken": await getCsrfToken() },
      body,
      credentials: "include",
    });
  } else if (method === "GET") {
    response = await fetch(`${API_HOST}/${route}`, {
      method,
      headers: {},
      credentials: "include",
    });
  }

  const data = await response.json();
  return data;
}
